(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("CNUI"), require("lodash"), require("react-dom"), require("cn-next")["Icon"], require("cn-next")["Menu"], require("cn-next")["Button"], require("cn-next")["Dialog"], require("cn-next")["Balloon"], require("cn-next")["ConfigProvider"], require("cn-next")["Message"], require("cn-next")["Progress"], require("cn-next")["Upload"], require("cn-next")["Loading"], require("cn-next")["Select"]);
	else if(typeof define === 'function' && define.amd)
		define(["react", "CNUI", "lodash", "react-dom", ["cn-next","Icon"], ["cn-next","Menu"], ["cn-next","Button"], ["cn-next","Dialog"], ["cn-next","Balloon"], ["cn-next","ConfigProvider"], ["cn-next","Message"], ["cn-next","Progress"], ["cn-next","Upload"], ["cn-next","Loading"], ["cn-next","Select"]], factory);
	else if(typeof exports === 'object')
		exports["wms-biz-components"] = factory(require("react"), require("CNUI"), require("lodash"), require("react-dom"), require("cn-next")["Icon"], require("cn-next")["Menu"], require("cn-next")["Button"], require("cn-next")["Dialog"], require("cn-next")["Balloon"], require("cn-next")["ConfigProvider"], require("cn-next")["Message"], require("cn-next")["Progress"], require("cn-next")["Upload"], require("cn-next")["Loading"], require("cn-next")["Select"]);
	else
		root["wms-biz-components"] = factory(root["React"], root["CNUI"], root["_"], root["ReactDOM"], root["CNUI"]["Icon"], root["CNUI"]["Menu"], root["CNUI"]["Button"], root["CNUI"]["Dialog"], root["CNUI"]["Balloon"], root["CNUI"]["ConfigProvider"], root["CNUI"]["Message"], root["CNUI"]["Progress"], root["CNUI"]["Upload"], root["CNUI"]["Loading"], root["CNUI"]["Select"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__57__, __WEBPACK_EXTERNAL_MODULE__85__, __WEBPACK_EXTERNAL_MODULE__86__, __WEBPACK_EXTERNAL_MODULE__119__, __WEBPACK_EXTERNAL_MODULE__160__, __WEBPACK_EXTERNAL_MODULE__167__, __WEBPACK_EXTERNAL_MODULE__170__, __WEBPACK_EXTERNAL_MODULE__330__, __WEBPACK_EXTERNAL_MODULE__331__, __WEBPACK_EXTERNAL_MODULE__333__, __WEBPACK_EXTERNAL_MODULE__615__) {
return 